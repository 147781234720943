import header from '../../../assets/images/soy_fibromialgia_en_positivo.png';

export const Header: React.FC = () => (
    <header id="header" className="header">
        <div className="container">
            <div className="row">
                <div className="col-lg-6 col-xl-5">
                    <div className="text-container">
                        <h1 className="h1-large">Soy fibromialgia en positivo</h1>
                        <p className="p-large">Aquí estamos unidos en un solo lugar, sin fronteras, diferentes naciones por un objetivo común, un mismo lenguaje. Aprende a vivir con fibro.</p>
                        {/* <a className="btn-solid-lg" href="#services">Offered services</a> */}
                    </div>
                </div>
                <div className="col-lg-6 col-xl-7">
                    <div className="image-container">
                        <img className="img-fluid" src={header} alt="alternative" />
                    </div>
                </div>
            </div>
        </div>
    </header>
)
import { BrowserRouter, Route, Switch, useLocation } from 'react-router-dom';
import { NavbarApp } from './shared/components/Navbar';
import './App.css';
import { Home } from './pages/home/Home';
import { Footer } from './shared/components/Footer';
import { Interview } from './pages/interview/Interview';
import { Blog } from './pages/blog/Blog';
import { NotFound } from './pages/notFound/Not-Found';
import { Terms } from './pages/terms/Terms';
import { useEffect } from 'react';
import { Contact } from './pages/Contact/Contact';
import { Testimony } from './pages/Testimony/Testimony';
import { BlogDetails } from './pages/blogDetail/BlogDetail';


function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <BrowserRouter>
    <ScrollToTop />
      <div className="App">
        <NavbarApp/>
        <Switch>
          <Route path="/" component={Home} exact/>
          <Route path="/entrevistas" component={Interview}/>
          <Route path="/fibro-consejos" component={Blog}/>
          <Route path="/fibro-consejos/:fibroId" component={BlogDetails}/>
          <Route path="/terminos-y-condiciones" component={Terms}/>
          <Route path="/contacto" component={Contact} />
          <Route path="/testimonio" component={Testimony} />
          <Route>
            <NotFound/>
          </Route>
        </Switch>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;

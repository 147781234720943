import { Fragment } from "react"
import { Header } from "../../shared/components/Header"
import "./contact.css";
export const Contact: React.FC = () => {
    return (
        <Fragment>
            <Header title='Contacto' />
            <div id="contact" className="basic-2">
                <div className="container">
                    <div className="row justify-content-center">
                    <div className="col-6 col-md-4">
                            <div className="social-container text-center py-3">
                                <h5>Grupo de facebook</h5>
                                <a href="https://www.facebook.com/groups/fibromialgiaenpositivoatrevete/" target="_blank" rel="noreferrer">
                                    <i className="fab fa-facebook-f fa-3x"></i>
                                </a>
                            </div>
                        </div>
                        <div className="col-6 col-md-4">
                            <div className="social-container text-center py-3">
                                <h5>Facebook</h5>
                                <a href="https://www.facebook.com/FibromialgiaEnPositivo" target="_blank" rel="noreferrer">
                                    <i className="fab fa-facebook-f fa-3x"></i>
                                </a>
                            </div>
                        </div>
                        <div className="col-6 col-md-4">
                            <div className="social-container text-center py-3">
                                <h5>Instagram</h5>
                                <a href="https://www.instagram.com/fibromialgia.enpositivo/" target="_blank" rel="noreferrer">
                                    <i className="fab fa-instagram fa-3x"></i>
                                </a>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="mod_height">
                                {/* <img src={ContactLogo} alt="Contacto" className="img-fluid" /> */}
                            </div>
                        </div>
                        <div className="col-6 col-md-4">
                            <div className="social-container text-center py-3">
                                <h5>Youtube</h5>
                                <a href="https://www.youtube.com/dianacristinaguerreroherrera" target="_blank" rel="noreferrer">
                                    <i className="fab fa-youtube fa-3x"></i>
                                </a>
                            </div>
                        </div>
                        <div className="col-6 col-md-4">
                            <div className="social-container text-center py-3">
                                <h5>E-mail</h5>
                                <a href="mailto:fibromialgia.positivo@gmail.com">
                                    <i className="fas fa-envelope fa-3x"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
import Interview from "../../../assets/images/entrevistas.png";
import Advice from "../../../assets/images/fibro-consejos.png";
import Contact from "../../../assets/images/contacto.png";
import { Link } from "react-router-dom";

export const Pages: React.FC = () => {
    return (
        <div className="cards-1 bg-gray">
        <div className="container">
            {/* <div className="row">
                <div className="col-lg-12">
                    <h2></h2>
                </div>
            </div> */}
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-icon">
                            {/* <span className="fas fa-headphones-alt"></span> */}
                            <img className="img-fluid" src={Interview} alt="alternative" />
                        </div>
                        <div className="card-body">
                            <h5 className="card-title text-center">Entrevistas</h5>
                            <p>Te comparto la suma de meses de investigación por medio de profesionales médicos que sí creen que existe la fibromialgia y estudian alternativas para mejorar la calidad de vida a pesar de tener dolor crónico.</p>
                            <div className="text-center">
                                <Link className="read-more no-line blue" to="/entrevistas">Ir a entrevistas <span className="fas fa-long-arrow-alt-right"></span></Link>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-icon red">
                            {/* <span className="far fa-clipboard"></span> */}
                            <img className="img-fluid" src={Advice} alt="alternative" />
                        </div>
                        <div className="card-body">
                            <h5 className="card-title text-center">Fibro consejos</h5>
                            <p>Ser positivos es una cuestión en nuestro carácter o personalidad que casi nos obliga a mejorar constantemente,  a exprimir un aprendizaje de toda experiencia que vivimos en pro de una mejoría. 💪💜</p>
                            <div className="text-center">
                                <Link className="read-more no-line blue" to="/fibro-consejos">Ir a fibro consejos <span className="fas fa-long-arrow-alt-right"></span></Link>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-icon green">
                            {/* <span className="far fa-comments"></span> */}
                            <img className="img-fluid" src={Contact} alt="alternative" />
                        </div>
                        <div className="card-body">
                            <h5 className="card-title text-center">Contacto</h5>
                            <p>Unidos en un solo lugar, sin fronteras, diferentes naciones por un objetivo común, todos comprendiendo un mismo lenguaje: el dolor. Por eso, aquí puedes contactarme.</p>
                            <br />
                            <div className="text-center">
                                <Link className="read-more no-line blue" to="/contacto">Contactar <span className="fas fa-long-arrow-alt-right"></span></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}
import { Modal } from "react-bootstrap"
import  logo  from '../../assets/images/logo_fibromialgia.png'

const styleIfram = {
    width: '100%',
    height: '100%'
};

const logoModal = {
    width: '160px',
    height: 'auto'
};

export const ModalMultimedia: React.FC<any> = (props: any) => {
    return (
        <Modal
        {...props}
        onEscapeKeyDown={() => props.onHide}
        size="lg"
        fullscreen={true}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        >
            <Modal.Header closeButton>
                <img src={logo} style={logoModal} alt="Fibromialgia en positiv+" className="img-fluid"/>
            </Modal.Header>
            <Modal.Body className="p-0" >
                <iframe style={styleIfram} src={props.interview?.url} title={props.interview?.title}></iframe>
            </Modal.Body>
        </Modal>
    )
}
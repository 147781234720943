import React, { useState } from 'react'
import  logo  from '../../assets/images/logo_fibromialgia.png'
import { Link, NavLink } from 'react-router-dom'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import { Container } from 'react-bootstrap'

export const NavbarApp: React.FC = () => {
    const [expanded, setExpanded] = useState(false);
    return (
        <Navbar bg="light" expand="lg" fixed="top" expanded={expanded}>
            <Container>
                <Navbar.Brand><Link className="navbar-brand logo-image" to="/"><img src={logo} alt="Fibromialgia en positiv+" /></Link></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(!expanded)}><span className="fa fa-bars fa-lg blue"></span></Navbar.Toggle>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ms-auto">
                        <ul className="navbar-nav navbar-nav-scroll">
                            <li className="nav-item">
                                <Nav.Link as={NavLink} onClick={() => setExpanded(false)} exact activeClassName="active" className="nav-link" to="/">Inicio</Nav.Link>
                            </li>
                            <li className="nav-item">
                                <Nav.Link as={NavLink} onClick={() => setExpanded(false)} exact activeClassName="active" className="nav-link" to="/testimonio">Testimonio</Nav.Link>
                            </li>
                            <li className="nav-item">
                                <Nav.Link as={NavLink} onClick={() => setExpanded(false)} exact activeClassName="active" className="nav-link" to="/entrevistas">Entrevistas</Nav.Link>
                            </li>
                            <li className="nav-item">
                                <Nav.Link as={NavLink} onClick={() => setExpanded(false)} exact activeClassName="active" className="nav-link" to="/fibro-consejos">Fibro consejos</Nav.Link>
                            </li>
                            <li className="nav-item">
                                <Nav.Link as={NavLink} onClick={() => setExpanded(false)} exact activeClassName="active" className="nav-link" to="/contacto">Contacto</Nav.Link>
                            </li>
                        </ul>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
        )
    }
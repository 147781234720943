

interface IProps {
    title: string 
}

export const Header: React.FC<IProps> = ({title}: IProps) => {
    return (
        <header className="ex-header">
            <div className="container">
                <div className="row">
                    <div className="col-xl-10 offset-xl-1">
                        <h2>{ title }</h2>
                    </div>
                </div>
            </div>
        </header>
    )
}
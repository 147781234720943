import http from "./http-commons";

const getEntries = () => {
  return http.get("/configurations/blog.json");
};


const BlogService = {
    getEntries
};

export default BlogService;
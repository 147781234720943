import React, { Fragment } from 'react'
import { Description } from './components/Description'
import { Group } from './components/Group'
import { Header } from './components/Header'
import { Pages } from './components/Pages'
import { Testimony } from './components/Testimony'
import Mejorar from "../../assets/images/mejorar_si_se_puede.jpg";
import FibroWhat from "../../assets/images/ilustraciones_por_Fibro_What.jpg";

export const Home: React.FC = () => {
  return (
    <Fragment>
        <Header />
        <Description />
        <Testimony />
        <Pages />
        <Group />
        <div className="slider-1">
          <div className="container">
              <div className="row">
                  <div className="col-lg-12">
                      <h4>FibroAmigos</h4>
                      <hr className="section-divider" />

                      <div className="container">
                          <div className="row">
                                <div className="col-12 col-md-3 offset-md-3">
                                    <a href="http://mejorarsisepuede.com/" target="_blank" rel="noopener noreferrer">
                                      <img className="img-fluid" src={Mejorar} alt="Mejorar_ si_se_puede" />
                                    </a>
                                </div>
                                <div className="col-12 col-md-3">
                                  <a href="https://www.instagram.com/fibro_what/" target="_blank" rel="noopener noreferrer">
                                    <img className="img-fluid" src={FibroWhat} alt="Ilustraciones_Fibro_What" />
                                  </a>
                                    
                                </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </Fragment>
  )
}
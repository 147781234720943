import { Fragment, useEffect, useState } from "react"
import BlogService from "../../services/BlogService";
import { Header } from "../../shared/components/Header"
import imgDefault from "../../assets/images/fibro-consejos_blog.png"
import { Card } from "react-bootstrap";
import "./blog.css";

export const Blog: React.FC = () => {

    const [entries, setEntries] = useState<any[]>();

	useEffect(() => {
			getEntries();
		}, []);

	const getEntries = () => {
		BlogService.getEntries()
			.then(response => {
				setEntries(response.data);
			})
			.catch(e => {
			console.log(e);
			});
	};
    return (
        <Fragment>
            <Header title='Fibro consejos' />
            <div className="container">
                <div className="row">
                    { entries && entries.map((entry, index) => (
                        <div key={index} className="col-12 col-md-4" >
                            <Card>
                                <Card.Img variant="top" src={ entry.image ? '/images/'+entry.image : imgDefault } alt={entry.title || 'Blog fibromialgia' } />
                                <Card.Body>
                                    <Card.Title>{ entry.title.substring(0, 40) + '...' }</Card.Title>
                                    <Card.Text>{ entry.excerpt }</Card.Text>
                                    <small className="text-muted"><i className="fas fa-user"></i> { entry.author }</small>
                                    {/* <small className="text-muted"><i className="fas fa-calendar-day"></i> { entry.date }</small> */}
                                </Card.Body>
                                <Card.Footer className="text-muted">
                                    <Card.Link href="#">Leer más</Card.Link>
                                </Card.Footer>
                            </Card>
                        </div>
                    )) }
                </div>
            </div>
            
        </Fragment>
    )
}
import { Fragment, useEffect, useState } from "react";
import { Header } from "../../shared/components/Header";
import TestimonyService from "../../services/TestimonyService";
import ITestimony from "../../types/ITestimony";

export const Testimony: React.FC = () => {
    const initialTestimonyState = {
        title: "",
        image: "",
        content: ""
      };

    const [currentTestimony, setCurrentTestimony] = useState<ITestimony>(initialTestimonyState);
    

    useEffect(() => {
        getTutorial();
      }, []);

    const getTutorial = () => {
    TestimonyService.getTestimony()
        .then(response => {
        setCurrentTestimony(response.data);
        })
        .catch(e => {
        console.log(e);
        });
    };
    return (
        <Fragment>
            <Header title={currentTestimony.title} />
            <div className="container pt-5">
                <div className="text-center container_outstanding_image">
                    <img src={currentTestimony.image} title={currentTestimony.title} className="img-fluid" alt="Diana Guerrero" />
                </div>
                <div dangerouslySetInnerHTML={ {__html: currentTestimony.content} }></div>
            </div>
        </Fragment>
    )
};

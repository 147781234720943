import React, { Fragment, useEffect, useState } from 'react'
import InterviewService from '../../services/InterviewServices';
import { Header } from '../../shared/components/Header'
import { ModalMultimedia } from '../../shared/components/ModalMultimedia';

export const Interview: React.FC = () => {

	const [interviews, setInterviews] = useState<any[]>();
	const [modalShow, setModalShow] = useState(false);
	const [currentInterview, setInterview] = useState<any>();

	useEffect(() => {
			getVideos();
		}, []);

	const getVideos = () => {
		InterviewService.getInterview()
			.then(response => {
				setInterviews(response.data);
			})
			.catch(e => {
			console.log(e);
			});
	};

	const showVideo = (interview: any) => {
		setModalShow(true);
		setInterview(interview);
	};
	return (
		<Fragment>
				<Header title='Entrevistas' />
				<div className="container">
					<div className="row">
						{ interviews && interviews.map((interview, index) => (
							<div key={index} className="col-12 col-md-4" onClick={() => showVideo(interview)} >
								<img src={'/images/'+interview.poster} alt="" className="img-fluid" />
								{/* <iframe src={interview.url} title={interview.title}></iframe> */}
								<p className="text-center py-2">{ interview.title }</p>
							</div>
						)) }
					</div>
				</div>
				<ModalMultimedia
					show={modalShow}
					interview={currentInterview}
					onHide={() => setModalShow(false)}
					></ModalMultimedia>
		</Fragment>
	)
}
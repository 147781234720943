import { Link } from "react-router-dom"

export const Testimony: React.FC = () => {
    return (
        <div id="services" className="cards-1 bg-gray">
        <div className="container">
            <div className="row">
                <div className="col-lg-12 text-center">
                    <h4>¿Cómo llegué a ser Fibromialgia en Positivo ? | Mi testimonio</h4>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12 text-center">
                    <p>"Mira que guapa y joven, mira que bien se ve, mira cómo sube la montaña, mira cómo hace deporte"… ¡Imposible que tenga fibromialgia!</p>
                    <h5>¿QUÉ SABRÁS TÚ?</h5>
                    <p>¿Qué sabrás tu de mi día a día, que sabrás tu de mi lucha mental diaria, qué sabrás tu de mis pensamientos y de lo que me anima a sonreír a diario?</p>
                    <p>Un día, después de meses y años paseando doctores especialistas y tantas pruebas clínicas, te dicen que tienes FIBROMIALGIA – ¿¡Qué!? Qué rayos es eso?</p>
                    <Link className="btn-solid-reg" to="/testimonio">Leer más</Link>
                </div>
            </div>
        </div>
    </div>
    )
}
import http from "./http-commons";
/* import ITestimony from "../types/ITestimony"; */

/* const getAll = () => {
  return http.get("/assets/configurations/testimony.json");
}; */

const getTestimony = () => {
    return http.get("/configurations/testimony.json");
  };

/* const get = (id: any) => {
  return http.get(`/tutorials/${id}`);
};

const create = (data: ITutorialData) => {
  return http.post("/tutorials", data);
};

const update = (id: any, data: ITutorialData) => {
  return http.put(`/tutorials/${id}`, data);
};

const remove = (id: any) => {
  return http.delete(`/tutorials/${id}`);
};

const removeAll = () => {
  return http.delete(`/tutorials`);
};

const findByTitle = (title: string) => {
  return http.get(`/tutorials?title=${title}`);
}; */

const TestimonyService = {
    getTestimony,
  /* get,
  create,
  update,
  remove,
  removeAll,
  findByTitle, */
};

export default TestimonyService;
import Background from '../../../assets/images/soy_fibro_squared.png'
export const Description: React.FC = () => {
    return (
        <div id="details" className="basic-2">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 col-xl-5 align-self-center">
                        <div className="image-container">
                            <img className="img-fluid" src={Background} alt="alternative" />
                        </div>
                    </div>
                    <div className="col-lg-7 col-xl-7">
                        <div className="text-container">
                            {/* <div className="section-title">WHAT WE DO</div> */}
                            <h4 className="text-center">Soy Fibromialgia en positivo - Somos fibromialgia en positivo</h4>
                            <p>Podemos contra la fibromialgia, juntos somos visibles. Unir nuestras voces, difundir información y demostrar que este padecimiento tiene mil caras y todas son reales.</p>  
                            <p>Guerreros contra la fibromialgia 💪💪💪💜💜💜</p> 
                            <p>El grupo de facebook, Fibromialgia en positivo, lo he creado con el fin de brindar consejos  para tener una calidad de vida con fibromialgia, una adecuada alimentación y nutrición, rutina diaria,  ejercicios, estiramientos, terapias alternativas, tratamientos médicos.</p>
                            <p>Somos una gran comunidad de aprendizaje y apoyo, una maravillosa familia que compartimos día a día nuestra experiencia, nuestras investigaciones y herramientas para vivir con fibromialgia. Sabemos que la fibromialgia no tiene cura, pero también hemos comprobado que podemos tener una vida de calidad.</p>
                            <p>Con positivismo logramos vivir con menos dolor, la energía positiva del universo nos ayuda a sobrellevar el peso, a olvidar que es imposible, nunca será perfecto  pero creemos en la  fibromialgia en positivo  💜💜💜💜</p>
                            {/* <a className="btn-solid-reg" href="#contact">Get quote</a> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
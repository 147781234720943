import GroupFacebook from "../../../assets/images/Encuentranos_facebook_fibromialgia_positivo.jpeg";
import Lily from "../../../assets/images/Lily_Reynaga_Fibromialgia_en_positivo.jpg";
import Arantxa from "../../../assets/images/arantxa-aguilar-fibromialgia-en-positivo.jpg";
import Mia from "../../../assets/images/mia_arana_fibromialgia_en_positivo.jpg";
import Monica from "../../../assets/images/monica_segura_fibromialgia_en_positivo.jpg";
import Paco from "../../../assets/images/paco_cruz_fibromialgia_en_positivo.jpg";

export const Group: React.FC = () => {
    return (
        <div className="basic-2">
            <div className="container">
                <div className="row">
                    <div className="col-lg-7 col-xl-7 align-self-center">
                        <div className="text-container">
                            {/* <div className="section-title">Nuestro grupo de Facebook</div> */}
                            <h4 className="text-center mb-4">Nuestro grupo de Facebook</h4>
                            <p>El grupo de Facebook Fibromialgia en Positivo, te brindará información actualizada sobre las últimas investigaciones sobre la fibromialgia. </p>
                            <p>En el encontrarás guías con artículos organizados sobre alimentación antiinflamatoria, ejercicios para el dolor, tratamientos alternativos, remedios naturales, recetas caseras...</p>
                            <p>Pero lo más importante, te unirás a una Comunidad que cada día crece como familia con apoyo mutuo, respeto, tolerancia y mucha vibra positiva para levantarnos el ánimo lo mejor posible con un lema en común:</p>
                            <p className="text-center blue"><strong>JUNTOS SOMOS VISIBLES.</strong></p>
                            <p>Únete a nuestra fibrofamilia para aprender con nosotros las herramientas necesarias para  vivir con fibro, vivir con calidad y una gran sonrisa.  ¡Te esperamos!</p>
                            <div className="text-center">
                                <a className="btn-solid-reg" href="https://www.facebook.com/FibromialgiaEnPositivo/" rel="noreferrer" target="_blank">Visitar grupo</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5 col-xl-5 align-self-center">
                        <div className="image-container">
                            <a href="https://www.facebook.com/FibromialgiaEnPositivo/" target="_blank" rel="noopener noreferrer">
                                <img className="img-fluid" src={GroupFacebook} alt="Encuentranos_facebook_fibromialgia_positivo" />
                            </a>
                            <h5 className="text-center my-3">Mi Fibro familia positiva</h5>
                            <p className="text-center"><small>Ellos son quienes me acompañan día a día en este proyecto especial para ti. Dedican cada día, su tiempo para mantener la armonía de una comunidad como ninguna otra</small></p>
                            <div className="container">
                                <div className="row">
                                    <div className="col px-1">
                                        <img className="img-fluid" src={Lily} alt="Lily_Reynaga_Fibromialgia_en_positivo" title="Lily Reynaga" />
                                    </div>
                                    <div className="col px-1">
                                        <img className="img-fluid" src={Arantxa} alt="arantxa-aguilar-fibromialgia-en-positivo" title="Arantxa Aguilar" />
                                    </div>
                                    <div className="col px-1">
                                        <img className="img-fluid" src={Mia} alt="mia_arana_fibromialgia_en_positivo" title="Mia Arana"/>
                                    </div>
                                    <div className="col px-1">
                                        <img className="img-fluid" src={Monica} alt="monica_segura_fibromialgia_en_positivo" title="Monica Segura"/>
                                    </div>
                                    <div className="col px-1">
                                        <img className="img-fluid" src={Paco} alt="paco_cruz_fibromialgia_en_positivo" title="Paco Cruz" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

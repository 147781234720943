import { Fragment } from "react";
import { Link } from "react-router-dom";
import patron from '../../assets/images/decoration-circles.png';
export const Footer: React.FC = () => (
    <Fragment>
    <div className="footer bg-gray">
        <img className="decoration-circles" src={patron} alt="alternative" />
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <h4>Buscamos investigar profesionalmente todo sobre la fibromialgia, colaboración con profesionales de la salud que sí creen en la patología.</h4>
                    <div className="social-container">
                        <span className="fa-stack">
                            <a href="https://www.facebook.com/FibromialgiaEnPositivo" target="_blank" rel="noreferrer">
                                <i className="fas fa-circle fa-stack-2x"></i>
                                <i className="fab fa-facebook-f fa-stack-1x"></i>
                            </a>
                        </span>
                        <span className="fa-stack">
                            <a href="https://www.instagram.com/fibromialgia.enpositivo/" target="_blank" rel="noreferrer">
                                <i className="fas fa-circle fa-stack-2x"></i>
                                <i className="fab fa-instagram fa-stack-1x"></i>
                            </a>
                        </span>
                        <span className="fa-stack">
                            <a href="mailto:fibromialgia.positivo@gmail.com">
                                <i className="fas fa-circle fa-stack-2x"></i>
                                <i className="fas fa-envelope fa-stack-1x"></i>
                            </a>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="copyright bg-gray">
        <div className="container">
            <div className="row">
                <div className="col-lg-5 col-md-12 col-sm-12">
                    <ul className="list-unstyled li-space-lg p-small">
                        {/* <li><a href="article.html">Article Details</a></li> */}
                        <li><Link to="terminos-y-condiciones">Términos & Condiciones</Link></li>
                        <li><Link to="politica-de-privacidad">Política de privacidad</Link></li>
                    </ul>
                </div>
                <div className="col-lg-3 col-md-12 col-sm-12">
                    <p className="p-small statement">Copyright © Fibromialgia+</p>
                </div>

                <div className="col-lg-4 col-md-12 col-sm-12">
                    <p className="p-small statement">Desarrollado por <a href="https://dianaguerrero.pro/otro-rollo-creativos" target="_blank" rel="noreferrer">Otro Rollo Creativos</a></p>
                </div>
            </div>
        </div>
    </div>
    </Fragment>
)
import http from "./http-commons";

const getInterview = () => {
  return http.get("/configurations/interviews.json");
};


const InterviewService = {
    getInterview
};

export default InterviewService;